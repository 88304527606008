import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import contactStyles from "./contact.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import leftCorner from "../../images/decorative-corner-left.svg"
import PolandMap from "../../images/kontakt/u-wierzby-pod-lasem_polska-mapa.jpg"
//Lightbox//
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ConcatedOptions from "../../components/lightbox";
import "../../components/lightbox.module.scss";

import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome'
import { faAt } from '../../../node_modules/@fortawesome/free-solid-svg-icons'


const ContactPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Kontakt | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Pensjonat U Wierzby Pod Lasem, Przyłęk 137, 64-300 Nowy Tomyśl, tel:+48 601 709 371"/>
        <link rel="canonical" href="https://www.uwierzby.pl/kontakt/"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${contactStyles.JumbotronSection___attraction}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">kontakt</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>zapraszamy do kontaktu</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Kontakt</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
          <Container fluid className={`MainSection_wrapper`}>
            <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
            <div className={`MainSection_textWrapper`}>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>A</p></div>
                <h1 className={`SectionTitle`}>kontakt</h1>
                <h3 className={`SectionSubTitleH3`}>zadzwoń lub napisz do nas</h3>
              </div>
              <div className={`MainSection_main`}>
              <div className={`MainSection_mainText limited`}>
                  <p>Rezerwacja telefoniczna do godz. <span>21:00</span></p>
                  <p className={contactStyles.HouseName}>Pensjonat U&nbsp;Wierzby Pod&nbsp;Lasem</p>
                  <p>Przyłęk 137<br/>64-300 Nowy Tomyśl</p>
                  <p>Wielkopolska<br/></p>
                  <p className={contactStyles.PhoneNumbers}>tel: <span>+48 601 709 371</span><br/>tel: <span>+48 667 719 372</span></p>
                  <p>wierzba32<FontAwesomeIcon icon={faAt}/>wp.pl</p>
                  <hr></hr>
                  <p><strong>Dojazd</strong></p>
                  <p>Pensjonat "U Wierzby Pod Lasem" jest położony w miejscowości Przyłęk w okolicy Nowego Tomyśla, leżącego w zachodniej części województwa Wielkopolskiego.<br/>
1 km od zjazdu z autostrady A2 - Autostrady Wolności: Poznań - Berlin. <br/>50 km od Poznania.</p>
<p>Lokalizacja naszej agroturystyki jest bardzo dogodna. W niskozurbanizowanym terenie, blisko natury, a jednocześnie niedaleko kilku dużych miast.</p>
                  <p><strong>Koordynaty GPS</strong>: szer.: 52.349342 | dł.: 16.11922</p><br/>
                </div>
              </div>
              <div className={contactStyles.PolandMapSection}>
                <img src={PolandMap} className={contactStyles.PolandMap} alt="Pensjonat U Wierzby Pod Lasem na mapie Polski"></img>
                <div class={contactStyles.CityDistance}>
                  <div class={contactStyles.CityDistanceRow}>
                    <div><strong>Miejscowość</strong></div><div><strong>Czas Dojazdu</strong></div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Świebodzin</div><div>40 min</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Poznań</div><div>50 min</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Gorzów Wielkopolski</div><div>1h</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Zielona Góra</div><div>1h</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Frankfurt nad Odrą</div><div>1h 10min</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Wrocław</div><div>2h</div>
                  </div>
                  <div class={contactStyles.CityDistanceRow}>
                    <div>Berlin</div><div>2h 30min</div>
                  </div>
                </div>
              </div>
            </div>
            <SimpleReactLightbox>
              <div className={`${contactStyles.ContactSection_fotoGrid}`}>
                <SRLWrapper options={ConcatedOptions}>
                    <a  className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___a}`}
                      href={require('../../images/kontakt/dojazd-photo01.jpg')} data-attribute="SRL">
                      <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo01.jpg")} alt="dojazd - zjazd z DK305"></img>
                    </a>
                    <a className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___b}`}
                        href={require('../../images/kontakt/dojazd-photo02.jpg')} data-attribute="SRL">
                        <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo02.jpg")} alt="dojazd"></img>
                    </a>
                    <a className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___c}`} 
                        href={require('../../images/kontakt/dojazd-photo03.jpg')} data-attribute="SRL">
                        <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo03.jpg")} alt="dojazd"></img>
                    </a>
                    <a className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___d}`} 
                        href={require('../../images/kontakt/dojazd-photo04.jpg')} data-attribute="SRL">
                        <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo04.jpg")} alt="dojazd"></img>
                    </a>
                    <a className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___e}`} 
                        href={require('../../images/kontakt/dojazd-photo05.jpg')} data-attribute="SRL">
                        <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo05.jpg")} alt="dojazd"></img>
                    </a>
                    <a className={`${contactStyles.FotoGrid_item} ${contactStyles.FotoGrid_item___f}`} 
                        href={require('../../images/kontakt/dojazd-photo06.jpg')} data-attribute="SRL">
                        <img className={contactStyles.FotoGrid_itemImg} src={require("../../images/kontakt/dojazd-photo06.jpg")} alt="dojazd"></img>
                    </a>    
                </SRLWrapper>
              </div>
            </SimpleReactLightbox>
            <div className={contactStyles.MapWrapper}>
                <iframe className={contactStyles.Map} title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9747.55205495851!2d16.1186388112576!3d52.35431632820886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47042a649b1d4a1f%3A0xc2290987cef17a04!2sAgroturystyka.+U+Wierzby+Pod+Lasem!5e0!3m2!1spl!2spl!4v1481997207163" height="660" style={{border: "0"}} allowfullscreen></iframe><a rel="nofollow" href="https://www.google.com/maps/dir//52.3493424,16.1192202/@52.3459885,16.1161457,15z?hl=pl-PL"><p id="link-do-trasy">wyznacz trasę dojazdu</p></a>
            </div>
        </Container>
      </section>
    </Layout>
  </>
);

export default ContactPage
